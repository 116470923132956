<template>
  <div class="mobile-container">
    <mobileStatusBar title="操作指引" />
    <div class="page-detail">
      <div class="name">{{ czzy.name }}</div>
      <div class="detail"><div v-html="czzy.content"></div></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileStatusBar from '@/components/mobileStatusBar'


export default {
  name: "GoodsCzzy",
  components: {
    mobileStatusBar,
  },
  data() {
    return {
      czzy: {
        name: '',
        content: ''
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.handleGetGoodsDetail(this.$route.query.id, this.$route.query.index)
    }
  },
  methods: {

    async handleGetGoodsDetail(id, index) {
      const { websiteInfo } = this
      var res = await this.$API.goodsDetail.post({
        Id: id,
        AdminId: websiteInfo.Id
      })
      if (res.error == 0) {
        try {
          res.info.Czzy = JSON.parse(res.info.Czzy)
        } catch (error) {
          res.info.Czzy = []
        }
        this.czzy = {
          name: res.info.Czzy[index].name,
          content: res.info.Czzy[index].content
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.mobile-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f7f7f7;
}
.page-detail {
  padding: .32rem;
  .name {
    line-height: .48rem;
    font-size: .32rem;
    font-weight: 500;
    color: #333;
  }
  .detail {
    margin-top: .32rem;
    color: #b3b3b3;
    font-size: .28rem;
    line-height: .48rem;
    /deep/ img {
      width: 100% !important;
      height: auto !important;
    }
    /deep/ video {
      width: 100% !important;
      height: auto !important;
    }
  }
}
</style>
